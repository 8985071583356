import React, { useState } from "react";
import "App.css";
import Form from "component/Validation/Form";

export default function GetInTouch() {
  const [requestername, setRequestername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [role, setRole] = useState("");
  const [interest, setInterest] = useState("");
  const [message, setMessage] = useState("");
  const [validate, setValidate] = useState({});
  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      requestername: {
        value: requestername,
        isRequired: true,
      },
      email: {
        value: email,
        isRequired: true,
      },
      phoneno: {
        value: phoneno,
        isRequired: true,
      },
      role: {
        value: role,
        isRequired: true,
      },
      interest: {
        value: interest,
        isRequired: true,
      },
      message: {
        value: message,
        isRequired: true,
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validate = validateRegister();
    if (validate) {
      setValidate({});
      setRequestername("");
      setEmail("");
      setPhoneno("");
      setRole("");
      setInterest("");
      setMessage("");
    }

    const data = {
      requestername,
      email,
      phoneno,
      role,
      interest,
      message,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch("https://api.ddevops.academy/getintouch", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        alert(JSON.stringify(response.data));
        console.log(response.data);
      })
      .catch((error) => {
        alert("Failed to Submit");
        console.log(error);
      });
  };
  return (
    <div>
      <section id="contact">
        <div className="container">
          <div className="contain">
            <div className="wrapper">
              <div className="form">
                <h4>GET IN TOUCH</h4>
                <h2 className="form-headline">Send us a message</h2>
                <form className="php-email-form" onSubmit={handleSubmit}>
                  <div className="form-group col-lg-12">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.requestername
                          ? "is-invalid "
                          : ""
                      }`}
                      id="requestername"
                      name="requestername"
                      placeholder="Full name"
                      value={requestername}
                      onChange={(e) => setRequestername(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.name
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.name
                        ? validate.validate.requestername[0]
                        : ""}
                    </div>
                  </div>
                  <div className="email mb-3">
                    <input
                      type="email"
                      className={`form-control ${
                        validate.validate && validate.validate.email
                          ? "is-invalid "
                          : ""
                      }`}
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.email
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.email
                        ? validate.validate.email[0]
                        : ""}
                    </div>
                  </div>

                  <div className="email mb-3">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.phoneno
                          ? "is-invalid "
                          : ""
                      }`}
                      id="phoneno"
                      name="phoneno"
                      value={phoneno}
                      placeholder="Phone No"
                      onChange={(e) => setPhoneno(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.phoneno
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.phoneno
                        ? validate.validate.phoneno[0]
                        : ""}
                    </div>
                  </div>
                  <div className="email mb-3">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.role
                          ? "is-invalid "
                          : ""
                      }`}
                      id="role"
                      name="role"
                      value={role}
                      placeholder="Role"
                      onChange={(e) => setRole(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.phoneno
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.phoneno
                        ? validate.validate.phoneno[0]
                        : ""}
                    </div>
                  </div>

                  <div className=" col-md-12">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.interest
                          ? "is-invalid "
                          : ""
                      }`}
                      id="interest"
                      name="interest"
                      value={interest}
                      placeholder="Interest"
                      onChange={(e) => setInterest(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.interest
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.interest
                        ? validate.validate.interest[0]
                        : ""}
                    </div>
                  </div>
                  <p className="full-width">
                    <textarea
                      minLength={20}
                      id="message"
                      cols={30}
                      rows={7}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Your Message*"
                      required
                      defaultValue={""}
                    />
                    <small />
                  </p>
                  <p className="full-width">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      defaultChecked
                    />
                    Yes, I would like to receive communications by call / email
                    about Company's services.
                  </p>
                  <p className="full-width">
                    <input
                      type="submit"
                      className="btn btn-sm btn-primary me-1"
                      defaultValue="Submit"
                      onclick="checkValidations()"
                    />
                  </p>
                </form>
              </div>
              <div className="contacts contact-wrapper">
                <ul>
                  <li>We believe in merit, quality an excellence.</li>
                  <span className="hightlight-contact-info">
                    <li className="email-info">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      info@ddevops.academy
                    </li>
                    <li>
                      <i className="fa fa-phone" aria-hidden="true" />
                      <span className="highlight-text">
                        +92 302 333 8677
                        <p
                          style={{
                            fontSize: "18px",
                            color: "black",
                            marginLeft: "100px",
                          }}
                        >
                          (DDEVOPS)
                        </p>
                      </span>
                      <br />
                    </li>
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
