import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import "App.css";
import Illution from "assets/img/kubernetes.jpg";
import Illution1 from "assets/img/teraform-01.jpg";
import Linux from "assets/img/linux.png";
import Devops from "assets/img/associatedevops.png";

export default function Courses() {
  return (
    <div>
      <section id="courses">
        <div className="text-center">
          <h2>Courses </h2>
        </div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pricing-contain">
              <CardGroup>
                <Card>
                  <Card.Body>
                    <Card.Title>Become A DevOps</Card.Title>
                    <Card.Text>
                      <img src={Devops} alt="right-pic" className="w-100" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Terms and conditions apply
                    </small>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>

            <div className="col-lg-5 pricing-contain ">
              <CardGroup>
                <Card>
                  <Card.Body>
                    <Card.Title>Advance Linux Course</Card.Title>
                    <Card.Text>
                      <img src={Linux} alt="linux" className="w-100" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Terms and conditions apply
                    </small>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pricing-contain">
              <CardGroup>
                <Card>
                  <Card.Body>
                    <Card.Title>Kubernetes</Card.Title>
                    <Card.Text>
                      <img src={Illution} alt="right-pic" className="w-100" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Terms and conditions apply
                    </small>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>

            <div className="col-lg-5 pricing-contain ">
              <CardGroup>
                <Card>
                  <Card.Body>
                    <Card.Title>Terraform</Card.Title>
                    <Card.Text>
                      <img src={Illution1} alt="right-pic" className="w-100" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Terms and conditions apply
                    </small>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
