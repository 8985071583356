import Register from "component/Validation";
import React from "react";

function FormView() {
  return (
    <>
      <div className="container">
        <h2 className="text-center mt-3">Registration Form</h2>
      </div>
      <div className="container">
        <Register />
      </div>
    </>
  );
}

export default FormView;
