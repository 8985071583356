import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "assets/img/icons/Logo.png";
import "App.css";
import Conditions from "component/Conditions";
export default function Navbars() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} height={40} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
              {/* <Nav.Link href="/#pricing">Pricing</Nav.Link> */}
              <Nav.Link href="/#courses">Courses</Nav.Link>
              <Nav.Link href="/#promos">Promo</Nav.Link>
              <Nav.Link href="/form">Registration</Nav.Link>
              <Nav.Link href="#conditions">
                <Conditions />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
