import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  function openFacebook() {
    window.open("https://www.facebook.com/ddevopsacademy");
  }
  function openInsta() {
    window.open("https://www.instagram.com/ddevops_academy/");
  }
  function openTwitter() {
    window.open("https://twitter.com/DDevopsAcademy");
  }
  function openDD() {
    window.open("https://www.ddevops.com/");
  }
  return (
    <div>
      <footer className="footer-section">
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; {year}, Powered by&nbsp;
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 100"
                      style={{ enableBackground: "new 0 0 100 100" }}
                      xmlSpace="preserve"
                    >
                      <style
                        type="text/css"
                        dangerouslySetInnerHTML={{
                          __html: "\n\t.st0{fill:#00AEEF;}\n",
                        }}
                      />
                      <path
                        className="st0"
                        d="M85.8,15.06c-7.03-7.2-15.68-11.8-24.79-13.85l0.02,14.23c7.11,2.3,13.66,6.78,18.56,13.49
c9.55,13.09,9.32,31.24-0.54,44.1c-5.86,7.64-13.9,12.26-22.41,13.89l0-7.48c6.17-1.49,11.95-4.97,16.3-10.47
c8.22-10.39,8.48-25.31,0.59-35.94c-3.39-4.57-7.73-7.82-12.49-9.8l0.04,47.87l-4.48,1.1V24.58V14.34V0.45C54.4,0.15,52.2,0.01,50,0
C37.33,0,24.66,4.76,14.93,14.33C-4.55,33.49-4.89,65.39,14.2,84.94c7.03,7.2,15.68,11.8,24.79,13.85l-0.02-14.23
c-7.11-2.3-13.66-6.78-18.56-13.49c-9.55-13.09-9.32-31.24,0.54-44.1c5.86-7.64,13.9-12.26,22.41-13.89l0,7.48
c-6.17,1.49-11.95,4.97-16.3,10.47c-8.22,10.39-8.48,25.31-0.59,35.94c3.39,4.57,7.73,7.82,12.49,9.8L38.93,28.9l4.48-1.1v47.61
v10.24v13.89c2.19,0.3,4.4,0.44,6.6,0.45c12.67,0,25.34-4.76,35.07-14.33C104.55,66.51,104.89,34.61,85.8,15.06z"
                      />
                    </svg>{" "}
                    <Link onClick={openDD}>DDEVOPS</Link>
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 text-lg-left">
                <div className="footer-social-icon">
                  <Link onClick={openFacebook}>
                    <i className="fa fa-facebook-f facebook-bg"></i>
                  </Link>

                  <Link onClick={openTwitter}>
                    <i className="fa fa-twitter twitter-bg"></i>
                  </Link>

                  <Link onClick={openInsta}>
                    <i className="fa fa-instagram insta-bg"></i>
                  </Link>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>

                    <li>
                      <a href="#pricing">Pricing</a>
                    </li>
                    <li>
                      <a href="#promos">Promos</a>
                    </li>
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
