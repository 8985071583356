import React from "react";
import { Fade } from "react-reveal";
import Banner from "assets/img/illustration/8.png";
import { Link } from "react-router-dom";

export default function Slider() {
  return (
    <>
      <section className="py-7 py-lg-10 main-banner" id="home">
        <div className="container ">
          <div className="row align-items-center h-100 justify-content-center justify-content-lg-start ">
            <div className="col-md-9 col-xxl-5 text-md-start text-center py-6 pt-8 ">
              <Fade up>
                <h1>
                  Dedicated DevOps
                  <br /> Academy
                </h1>
                <p>
                  Excellence In Tech Education And Emerging
                  <br /> Technologies.
                </p>
              </Fade>

              <div>
                <Fade up>
                  <Link className="btn btn-primary" to="/form">
                    Registeration
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        <div className="container">
          <div className="row flex-center">
            <div className="col-md-6 text-center text-md-start">
              <h2>Learn Smartly With Dedicated DevOps Academy</h2>
              <p>
                A network of leading academic research institutions in the
                sector of IT education.
              </p>

              <ul>
                <li>
                  {" "}
                  <b>- </b> Enhance teaching practices{" "}
                </li>
                <li>
                  {" "}
                  <b>- </b>Learning through implementations{" "}
                </li>
                <li>
                  {" "}
                  <b>- </b>Appropriate tools, techniques, or processes{" "}
                </li>
                <li>
                  {" "}
                  <b>- </b>Tech Development & Comunity Involvement{" "}
                </li>
                <li>
                  {" "}
                  <b>- </b>Facilitate to increase the technological senses
                </li>
                <li>
                  {" "}
                  <b>- </b>Personalized Learning Opportunities
                </li>
              </ul>
            </div>
            <div className="col-md-6 mb-4">
              <img className="w-100" src={Banner} alt="..." />
            </div>
          </div>
        </div>
      </>
    </>
  );
}
