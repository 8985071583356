import React from "react";
import { Button, Modal } from "react-bootstrap";
import "App.css";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2 className="text-center">Terms & Conditions</h2>
        <div className="terms">
          <div className="contacts">
            <ul>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Refundable and non refundable on different conditions.
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Formula = pass course - remaining = ?.(For those who paid full)
              </li>

              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Registration fee is not refundable.
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Bring your own gadgets (Laptop).
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                For certificate issuance all courses must be passed.
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Re attempt exam fee will be charged.
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Students attend classes on alternate days.
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Violation of discipline will lead to immediate
                <span className="highlight-text-grey"> TERMINATION.</span>
              </li>
              <li>
                <i className="fa fa-check" style={{ marginRight: "20px" }}></i>
                Internship opportunity for deserving students and refer to other
                I.T companies for internship.
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Conditions() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="btn btn-sm btn-primary me-1"
        onClick={() => setModalShow(true)}
      >
        Terms
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Conditions;
