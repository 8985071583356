import React from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { Button, Form } from "react-bootstrap";

class Register extends React.Component {
  state = {
    name: "",
    cname: "",
    cnic: "",
    homeno: "",
    contact: "",
    address: "",
    lastname: "",
    qualification: "",
    email: "",
    nationality: "",
    market: "",
    course: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      btnStatus: true,
    };
    this.handlename = this.handlename.bind(this);
    this.handlecname = this.handlecname.bind(this);
    this.handlecnic = this.handlecnic.bind(this);
    this.handlenationality = this.handlenationality.bind(this);
    this.handlehomeno = this.handlehomeno.bind(this);
    this.handlemarket = this.handlemarket.bind(this);
    this.handlecontact = this.handlecontact.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleCourse = this.handleCourse.bind(this);
    this.handlequalification = this.handlequalification.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.formatPhoneText1 = this.formatPhoneText1.bind(this);
    this.replaceAll1 = this.replaceAll1.bind(this);
    this.formatPhoneText = this.formatPhoneText.bind(this);
    this.handlelastname = this.handlelastname.bind(this);
    this.replaceAll = this.replaceAll.bind(this);
    this.onLaunchClicked = this.onLaunchClicked.bind(this);
  }

  formatPhoneText(value) {
    value = this.replaceAll(value.trim(), "-", "");
    if (value.length > 5 && value.length <= 12)
      value = value.slice(0, 5) + "-" + value.slice(5);
    else if (value.length > 12)
      value =
        value.slice(0, 5) + "-" + value.slice(5, 12) + "-" + value.slice(12);
    return value;
  }
  replaceAll(src, search, replace) {
    return src.split(search).join(replace);
  }

  formatPhoneText1(value) {
    value = this.replaceAll1(value.trim(), "-", "");
    if (value.length > 4 && value.length <= 11)
      value = value.slice(0, 4) + "-" + value.slice(4);
    return value;
  }
  replaceAll1(src, search, replace) {
    return src.split(search).join(replace);
  }
  handlename(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handlenationality(e) {
    this.setState({
      nationality: e.target.value,
    });
  }
  handlemarket(e) {
    this.setState({
      market: e.target.value,
    });
  }

  handlecname(e) {
    this.setState({
      cname: e.target.value,
    });
    e.target.value = this.formatPhoneText(e.target.value);
  }

  handlehomeno(e) {
    this.setState({
      homeno: e.target.value,
    });
    e.target.value = this.formatPhoneText1(e.target.value);
  }

  handlecontact(e) {
    this.setState({
      contact: e.target.value,
    });
    e.target.value = this.formatPhoneText1(e.target.value);
  }
  handlequalification(e) {
    this.setState({
      qualification: e.target.value,
    });
  }

  handleCourse(e) {
    this.setState({
      course: e.target.value,
    });
  }

  handleAddress(e) {
    this.setState({
      address: e.target.value,
    });
  }
  handlelastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }
  handleemail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handlecnic(e) {
    this.setState({
      cnic: e.target.value,
    });
    e.target.value = this.formatPhoneText1(e.target.value);
  }

  handleSubmit() {
    const obtainedData = {
      name: this.state.name,
      cname: this.state.cname.replace(/-/g, ""),
      homeno: this.state.homeno,
      lastname: this.state.lastname,
      contact: this.state.contact,
      qualification: this.state.qualification,
      course: this.state.course,
      address: this.state.address,
      nationality: this.state.nationality,
      email: this.state.email,
      market: this.state.market,
    };
    this.setState({ btnStatus: false });
    axios({
      method: "POST",
      url: `https://api.ddevops.academy/adduser`,
      data: obtainedData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) alert(response.data.data);
        // window.location = "/";
      })
      .catch((error) => {
        alert("Failed to Submit");
        this.setState({ btnStatus: true });
        // console.log(error);
      });
    this.onLaunchClicked();
  }
  onLaunchClicked(event) {
    event.preventDefault();
    this.setState({
      isButtonDisabled: true,
    });

    // **** here's the timeout ****
    setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);
    console.log("set", setTimeout);

    return this.props.onLaunchClicked();
  }
  validation() {
    var teacher = document.getElementById("teacher").value;
    var qualification = document.getElementById("qualification").value;
    var course = document.getElementById("course").value;
    var cnic = document.getElementById("cnic").value;
    var mob = document.getElementById("mob").value;
    var email = document.getElementById("email").value;
    var personal = document.getElementById("personal").value;
    var address = document.getElementById("address").value;
    var last = document.getElementById("last").value;
    var market = document.getElementById("market").value;

    var usercheck = /^[A-Za-z. ]{3,30}$/;
    var lastcheck = /^[A-Za-z. ]{3,30}$/;
    var cniccheck = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;
    var mobcheck = /^[0-9]{4}-[0-9]{7}$/;
    var personalcheck = /^[0-9]{4}-[0-9]{7}$/;
    var emailcheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var qualificationcheck = /^[A-Za-z]+$/;
    // =============================name=============================
    if (usercheck.test(teacher)) {
      document.getElementById("usererror").innerHTML = " ";
    } else {
      document.getElementById("usererror").innerHTML = "** Username Is Invalid";
      return false;
    }

    // =============================Qualification======================
    if (qualification !== "Choose...") {
      document.getElementById("qualificationerror").innerHTML = " ";
    } else {
      document.getElementById("qualificationerror").innerHTML =
        "** Please Select from Dropdown";
      return false;
    }
    // =============================Course======================
    if (course !== "Choose...") {
      document.getElementById("courseerror").innerHTML = " ";
    } else {
      document.getElementById("courseerror").innerHTML =
        "** Please Select from Dropdown";
      return false;
    }

    // =============================CNIC======================
    if (cniccheck.test(cnic)) {
      document.getElementById("cnicerror").innerHTML = " ";
      console.log(cnic);
    } else {
      document.getElementById("cnicerror").innerHTML = "** Cnic Is Invalid";
      return false;
    }

    // =============================Personal Number======================
    if (personalcheck.test(personal)) {
      document.getElementById("personalerror").innerHTML = " ";
    } else {
      document.getElementById("personalerror").innerHTML =
        "** Mobile number is invalid";
      return false;
    }
    // =============================Mob=========================================

    if (mobcheck.test(mob)) {
      document.getElementById("moberror").innerHTML = " ";
    } else {
      document.getElementById("moberror").innerHTML =
        "** Mobile number is invalid";
      return false;
    }
    // =============================last Name======================
    if (lastcheck.test(last)) {
      document.getElementById("fathererror").innerHTML = " ";
    } else {
      document.getElementById("fathererror").innerHTML =
        "** Last name is invalid";
      return false;
    }

    // =======================Email============================
    if (emailcheck.test(email)) {
      document.getElementById("emailerror").innerHTML = " ";
    } else {
      document.getElementById("emailerror").innerHTML = "** Email is invalid";
      return false;
    }

    // =============================Address======================
    if (address.length) {
      document.getElementById("addresserror").innerHTML = " ";
    } else {
      document.getElementById("addresserror").innerHTML =
        "** Address can not be empty";
      return false;
    }
    // ==========================Market By============================
    if (market !== "Choose...") {
      document.getElementById("marketerror").innerHTML = " ";
    } else {
      document.getElementById("marketerror").innerHTML =
        "** Please Select from Dropdown";
      return false;
    }
    this.handleSubmit();
  }
  render() {
    return (
      <>
        <Fade bottom>
          <Form className="row php-email-form">
            <div className="container">
              <div className="col-lg-12 mt-3">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    id="teacher"
                    className="form-control"
                    onChange={this.handlename}
                  />
                  <span
                    id="usererror"
                    className="text-danger font-weight-bold"
                  ></span>
                </Form.Group>
              </div>
              <div className="col-lg-12 mt-3">
                {" "}
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="last"
                    className="form-control"
                    placeholder=""
                    onChange={this.handlelastname}
                  />
                  <span
                    id="fathererror"
                    className="text-danger font-weight-bold"
                  ></span>
                </Form.Group>
              </div>

              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="">
                    <label>CNIC</label>
                    <input
                      type="text"
                      id="cnic"
                      maxLength="15"
                      className="form-control"
                      placeholder="*****-*******-*"
                      onChange={this.handlecname}
                    />
                    <span
                      id="cnicerror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  {" "}
                  <div className="">
                    <label>Email</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder=""
                      onChange={this.handleemail}
                    />
                    <span
                      id="emailerror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="">
                    <label>Contact No: </label>
                    <input
                      type="text"
                      id="mob"
                      maxLength="12"
                      className="form-control"
                      placeholder="****-*******"
                      onChange={this.handlecontact}
                    />
                    <span
                      id="moberror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  {" "}
                  <div className="">
                    <label>Last Qualification</label>
                    <select
                      className="form-control"
                      id="qualification"
                      style={{ height: "50px" }}
                      onChange={this.handlequalification}
                    >
                      <option selected>Choose...</option>
                      <option>Matric</option>
                      <option>Intermediate</option>
                      <option>Bachelors</option>
                      <option>Masters</option>
                      <option>Others</option>
                    </select>
                    <span
                      id="qualificationerror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  {" "}
                  <div className="">
                    <label>Select Course</label>
                    <select
                      className="form-control"
                      id="course"
                      style={{ height: "50px" }}
                      onChange={this.handleCourse}
                    >
                      <option selected>Choose...</option>
                      <option>Become A DevOps</option>
                      <option>Advance Linux</option>
                      <option>Kubernetes</option>
                      <option>Terraform</option>
                      <option>Others</option>
                    </select>
                    <span
                      id="courseerror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="">
                    <label>Home No:</label>
                    <input
                      type="text"
                      id="personal"
                      maxLength="12"
                      className="form-control"
                      placeholder="****-*******"
                      onChange={this.handlehomeno}
                    />
                    <span
                      id="personalerror"
                      className="text-danger font-weight-bold"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="">
                  <label>Nationality</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handlenationality}
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                {" "}
                <div className="">
                  <label>Address</label>
                  <textarea
                    id="address"
                    rows={4}
                    className="form-control"
                    placeholder=""
                    onChange={this.handleAddress}
                  />
                  <span
                    id="addresserror"
                    className="text-danger font-weight-bold"
                  ></span>
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                {" "}
                <div className="">
                  <label>How You Know About Us?</label>
                  <select
                    className="form-control"
                    id="market"
                    style={{ height: "50px" }}
                    onChange={this.handlemarket}
                  >
                    <option selected>Choose...</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                    <option>Twitter</option>
                    <option>LinkedIn</option>
                    <option>Friends/Family</option>
                  </select>
                  <span
                    id="marketerror"
                    className="text-danger font-weight-bold"
                  ></span>
                </div>
              </div>
              <br />
              <br />
              <Fade top>
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3 text-center">
                    <Button
                      onClick={() => {
                        this.validation();
                      }}
                      //disabled={false}
                      className="btn btn-primary mx-auto mb-5"
                      // disabled={this.state.isButtonDisabled}
                      disabled={!this.state.btnStatus}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="col-md-3 text-center">
                    <Button
                      onClick={() => {
                        window.location = "/";
                      }}
                      className="btn btn-danger mx-auto mb-5"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </Fade>
            </div>
          </Form>
        </Fade>
      </>
    );
  }
}

export default Register;
