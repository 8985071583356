import React from "react";
import { Fade } from "react-reveal";
import Roadmap from "assets/img/Roadmap.png";
import "App.css";
import Courses from "component/Courses";
import Slider from "component/Slider";
// import Pricing from "component/Pricing";
import Promo from "component/Promo";
import GetInTouch from "component/Touch";
function Home() {
  return (
    <div>
      <Slider />
      {/* <RegistrationView/> */}
      <div className="container">
        <div className="row">
          <h1 className="text-center">Course Road Map</h1>
          <div className="col-md-12 ">
            <img src={Roadmap} alt="reaodmap" className="text-center w-100" />
          </div>
        </div>
      </div>
      {/* <Pricing /> */}
      <Courses />
      <Fade up>
        <section>
          <div>
            <h1 className="text-center">Our Promos</h1>
          </div>
          <Promo />
        </section>
      </Fade>
      <GetInTouch />
      {/* <GetStarted/> */}
    </div>
  );
}

export default Home;
