import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "App.css";
import ReactGA from "react-ga";
import { useEffect } from "react";
import Navbars from "component/Navbar";
import Home from "pages/Home";
import FormView from "pages/Form";
import Success from "pages/Success";
import Footer from "component/Footer";
const TRACKING_ID = "UA-226418885-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="App">
      <Router>
        <Navbars />
        <main>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/form" exact>
              <FormView />
            </Route>
            <Route path="/success" exact>
              <Success />
            </Route>
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
