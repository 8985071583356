import React from "react";

export default function Promo() {
  return (
    <>
      <section id="promos">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="dl pricing-contain">
                <div className="brand"></div>
                <div className="discount alizarin">
                  Discount
                  <div className="type">Punch</div>
                </div>
                <div className="descr">
                  <ul className="style-check">
                    <li>
                      Upto 25% discount over full course payment in advance.
                    </li>
                    <li>No Registration fees</li>
                    <li>1 Exam Re attempt free.</li>
                    <li>Swags</li>
                  </ul>
                </div>
                <div className="ends">
                  <small>Terms and conditions apply.</small>
                </div>
              </div>
            </div>
            <br />
            <div className="col-lg-6 ">
              <div className="dl pricing-contain">
                <div className="brand"></div>
                <div className="discount emerald">
                  Referral
                  <div className="type">+ </div>
                </div>
                <div className="descr">
                  <ul className="style-check">
                    <li>Referral bonus</li>
                    <li>Special discount (Institutes)</li>
                    <li>Weekend class (Only for companies & special cases)</li>
                    <li>Swags</li>
                  </ul>
                </div>
                <div className="ends">
                  <small>Terms and conditions apply.</small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="dl pricing-contain">
                <div className="brand">{/* <h2>Promo 1</h2> */}</div>
                <div className="discount alizarin">
                  Hum
                  <div className="type">Paanch</div>
                </div>
                <div className="descr">
                  <ul className="style-check">
                    <li>
                      Minimun <strong>5</strong> members
                    </li>
                    <li>
                      <strong> 5% </strong> discount on monthly fees for each
                      member.{" "}
                    </li>
                    <li>1 Exam Re attempt free (per member).</li>
                    <li>
                      Nomination for Re attempt will not be accepted.{" "}
                      <strong> </strong>
                    </li>
                    <li>Swags</li>
                  </ul>
                </div>
                <div className="ends">
                  <small>Terms and conditions apply.</small>
                </div>
              </div>
            </div>
            <br />
            <div className="col-lg-6 ">
              <div className="dl pricing-contain">
                <div className="brand">{/* <h2>Promo 2</h2> */}</div>
                <div className="discount peter-river">
                  10 KA
                  <div className="type">DUM</div>
                </div>
                <div className="descr">
                  <ul className="style-check">
                    <li>
                      minimum <strong>10</strong> members
                    </li>
                    <li>
                      {" "}
                      <strong>10% </strong>discount on monthly fees for each
                      member{" "}
                    </li>
                    <li> 1 Exam Re attempt free (per member).</li>
                    <li> Nomination for Re attempt will not be accepted.</li>
                    <li>Swags</li>
                  </ul>
                </div>
                <div className="ends">
                  <small>Terms and conditions apply.</small>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container text-center mt-3">
            <strong>Note: No registration fee for first 15 candidates. </strong>
          </div> */}
        </div>
      </section>
    </>
  );
}
